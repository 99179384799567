<script>
    import { SequenceStepChunkTextAttachedToArrow } from '@/sequences/SequenceStepChunk/chunk_text_attached_to_arrow/index'
    import activator_position_watcher from '@/sequences/SequenceStepChunk/utils/activator_position_watcher'
    import unique_id_mixin from '@/sequences/SequenceStepChunk/utils/unique_id_mixin'

    const BASE_CLASS = 'chunk-with-text-attached-to-arrow'
    const applyBaseClassModificator = modificator => `${BASE_CLASS}--${modificator}`

    const POSITIONING_CLASSES = {
        [SequenceStepChunkTextAttachedToArrow.POSITIONS.TOP]: applyBaseClassModificator`position-top`,
        [SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT]: applyBaseClassModificator`position-right`,
        [SequenceStepChunkTextAttachedToArrow.POSITIONS.BOTTOM]: applyBaseClassModificator`position-bottom`,
        [SequenceStepChunkTextAttachedToArrow.POSITIONS.LEFT]: applyBaseClassModificator`position-left`
    }

    const ALIGNMENT_CLASSES = {
        [SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP]: applyBaseClassModificator`align-top`,
        [SequenceStepChunkTextAttachedToArrow.ALIGNMENT.RIGHT]: applyBaseClassModificator`align-right`,
        [SequenceStepChunkTextAttachedToArrow.ALIGNMENT.BOTTOM]: applyBaseClassModificator`align-bottom`,
        [SequenceStepChunkTextAttachedToArrow.ALIGNMENT.LEFT]: applyBaseClassModificator`align-left`
    }

    const WINDOW_SCROLLBAR_WIDTH = 17

    export default {
        name: "ChunkTextAttachedToArrow",
        _name: "ChunkTextAttachedToArrow",
        mixins: [activator_position_watcher, unique_id_mixin],
        render() {
            return null
        },
        props: {
            sequenceId: String,
            stepId: String,
            chunkId: String,
            chunkDefinition: {
                type: Object,
                required: true
            },
            anchorSelector: {
                type: String,
                required: true
            }
        },
        data: () => ({
            el: null,
        }),
        computed: {
            classes() {
                return [
                    BASE_CLASS,
                    POSITIONING_CLASSES[this.chunkDefinition.position],
                    ALIGNMENT_CLASSES[this.chunkDefinition.align],
                    ...(this.chunkDefinition.withoutArrow ? [applyBaseClassModificator('without-arrow')] : [])
                ]
            },
            style() {
                if (!this.isMounted_)
                    return

                const toReturn = {'z-index': 1000, 'max-width': '400px'}
                const position = this.chunkDefinition.position
                const align = this.chunkDefinition.align


                // POSITION
                if (position === SequenceStepChunkTextAttachedToArrow.POSITIONS.TOP)
                    toReturn.bottom = (window.innerHeight - this.activatorBounding.top) + 'px'
                if (position === SequenceStepChunkTextAttachedToArrow.POSITIONS.RIGHT)
                    toReturn.left = this.activatorBounding.right + 'px'
                if (position === SequenceStepChunkTextAttachedToArrow.POSITIONS.BOTTOM)
                    toReturn.top = this.activatorBounding.bottom + 'px'
                if (position === SequenceStepChunkTextAttachedToArrow.POSITIONS.LEFT)
                    toReturn.right = (window.innerWidth - this.activatorBounding.left) + 'px'


                // ALIGN
                if (align === SequenceStepChunkTextAttachedToArrow.ALIGNMENT.TOP)
                    toReturn.top = this.activatorBounding.top + 'px'
                if (align === SequenceStepChunkTextAttachedToArrow.ALIGNMENT.RIGHT)
                    toReturn.right = (window.innerWidth - this.activatorBounding.right - WINDOW_SCROLLBAR_WIDTH) + 'px'
                if (align === SequenceStepChunkTextAttachedToArrow.ALIGNMENT.BOTTOM)
                    toReturn.bottom = (window.innerHeight - this.activatorBounding.bottom) + 'px'
                if (align === SequenceStepChunkTextAttachedToArrow.ALIGNMENT.LEFT)
                    toReturn.left = this.activatorBounding.left + 'px'

                return Object.entries(toReturn).map(([k, v]) => `${k}:${v}`).join(';')
            }
        },
        watch: {
            classes(v) {
                if (this.el) {
                    this.el.removeAttribute('class')

                    this.el.classList.add(...v)
                }
            },
            style(v) {
                if (this.el) {
                    this.el.style = v
                }
            },
            $props: {
                handler() {
                    this.handleWindowResize()
                    this.mountEl()
                },
                deep: true
            }
        },
        mounted() {
            setTimeout(() => {
                this.mountEl()
            }, 10)

        },
        beforeDestroy() {
            this.rmEl()
        },
        methods: {
            rmEl() {
                if (this.el)
                    this.el.remove()

                if (this.getEl())
                    this.getEl().remove()

            },
            mountEl() {
                this.rmEl()

                this.el = document.createElement('div')
                this.el.id = this.uniqueId

                let innerHTML = this.chunkDefinition.mainText

                this.el.classList.add(...this.classes)
                this.el.style = this.style

                this.chunkDefinition.addons.forEach(addon => {
                    innerHTML += addon.genElement()
                })

                this.el.innerHTML = innerHTML

                document.querySelector(this.SEQUENCES_CONTROL_ID).appendChild(this.el)
            }
        }
    }
</script>

<style lang="scss">

</style>